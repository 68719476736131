import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Adjust the path as necessary
import "./components/special_styles.css"
import NumberInput from "./components/NumberInput"
import DateInput from "./components/DateInput"
import ColorWheel from "./components/ColorWheel"
import Acknowledgment from "./components/Acknowledgment";


export default function Login() {
    const [date, setSelectedDate] = useState('');
    const [colour, setSelectedColor] = useState('yellow');
    const [number, setSelectedValue] = useState(1);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // State to store the error message
    const [isFormVisible, setIsFormVisible] = useState(false); // State to control the visibility of the form container

    const navigate = useNavigate();
    const { login } = useAuth();

    const handleSubmit = async event => {
        event.preventDefault();
        try {
            const response = await axios.post('https://www.nicandamanda.com/login', { date, colour, number });

            if (response.data === "Login Successful") {
                login(true); // Update the global state to indicate login success
                navigate('/home'); // Redirect to Home on success
            } else {
                // Instead of alert, set an error message to be displayed
                setErrorMessage('Hmm are you sure? 🤔');
            }
        } catch (error) {
            console.error('Login Error', error);
            setErrorMessage('An error occurred. ⚠️');
        }
    }

    const handleValueChange = (number) => {
        setSelectedValue(number);
    };

    const handleColorChange = (color) => {
        setSelectedColor(color);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const toggleFormVisibility = () => {
        setIsFormVisible(!isFormVisible);
    };

    return (
        <div className='main-container'>
            <div className={`title-container ${isFormVisible ? 'shifted' : ''}`}>
                <div className="title-text">
                    <p className="intro">Welcome to...</p>
                    <h1 className="page-title">Nic ♡ Amanda's</h1>
                    <p className="outro">Anniversary Website</p>
                </div>
                <img src="gifs/titleGif.gif" style={{ height: "30%", marginLeft: "50px"}}></img>
            </div>
            <div className={`form-container ${isFormVisible ? 'visible' : ''}`}>
                <form className="login-form" onSubmit={handleSubmit}>
                    <h2>User Authentication</h2>
                    <div className="question">
                        <NumberInput min={1} max={10} step={1} onValueChange={handleValueChange} />
                    </div>
                    <div className="question">
                        <DateInput onDateChange={handleDateChange} />
                    </div>
                    <div className="question">
                        <ColorWheel onColorChange={handleColorChange} />
                    </div>
                    <div className="question">
                        <div className="go-outer">
                            {errorMessage && <div className="error-message alert alert-danger" role="alert">{errorMessage}</div>}
                            <div className="go">
                                <label className="go-title"><h2>Go!</h2></label>
                                <button className="btn-class-name">
                                    <span className="back"></span>
                                    <span className="front"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="tab" onClick={toggleFormVisibility}>
                    <span>{isFormVisible ? 'Close' : 'Open'} Login</span>
                </div>
            </div>
            <Acknowledgment />
        </div>
    );
}
