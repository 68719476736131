import React, { useState } from 'react';

const DateInput = ({ onDateChange }) => {
    const [date, setDate] = useState('');

    const handleChange = (e) => {
        const newDate = e.target.value;
        setDate(newDate);
        onDateChange(newDate);
    };

    return (
        <div className="date-input-container">
            <label htmlFor="date-input"><h6 class="date-title">What is our Anniversary?</h6></label>
            <div class="date-input"><input
                id="date-input"
                type="date"
                value={date}
                onChange={handleChange}
            /></div>
            
        </div>
    );
};

export default DateInput;
