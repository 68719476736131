import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { AuthProvider } from './AuthContext';
import ProtectedRoute from './ProtectedRoute';

// import containers (the different website pages)
import Home from './containers/Home';
import Gallery from './containers/Gallery';
import About from './containers/About';
import Places from './containers/Places';
import Things from './containers/Things';
import Timeline from './containers/Timeline';
import Messages from './containers/Messages';
import Login from './Login';

import NavBar from "./components/NavBar";
import Head from "./components/Head";


const Layout = () => {
  const location = useLocation(); // Hook to access the current location
  const showNavbar = location.pathname !== "/"; // Determine whether to show the Navbar

  return (
    <>
      {showNavbar && <NavBar />}
      <Head />
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/Home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
        <Route exact path="/Gallery" element={<ProtectedRoute><Gallery /></ProtectedRoute>} />
        <Route exact path="/About" element={<ProtectedRoute><About /></ProtectedRoute>} />
        <Route exact path="/Places" element={<ProtectedRoute><Places /></ProtectedRoute>} />
        <Route exact path="/Things" element={<ProtectedRoute><Things /></ProtectedRoute>} />
        <Route exact path="/Timeline" element={<ProtectedRoute><Timeline /></ProtectedRoute>} />
        <Route exact path="/Messages" element={<ProtectedRoute><Messages /></ProtectedRoute>} />
        {/* Add other routes as needed */}
      </Routes>
    </>
  );
};

export default function App() {
  return (
    <AuthProvider>
      <Router>
        {/* Render the Layout component that includes conditional Navbar rendering */}
        <Layout />
      </Router>
    </AuthProvider>
  );
}