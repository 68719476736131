import React, { useState } from 'react';
import './styles.css';

const colors = ['yellow', 'green', 'blue', 'purple', 'red', 'orange'];

const ColorWheel = ({ onColorChange }) => {
    const [angle, setAngle] = useState(240);
    const [currentColorIndex, setCurrentColorIndex] = useState(0);

    const handleClick = () => {
        var newIndex = currentColorIndex - 1;
        if (newIndex < 0) {
            newIndex = 5
        }

        setCurrentColorIndex(newIndex);
        setAngle(angle + 60); // Each segment is 60 degrees
        onColorChange(colors[newIndex]);
    };

    return (
        <div class="colour-wheel">
            <label><h6>What is Amanda's Favourite Colour?</h6></label>
            <div class="wheel-holder">
                <div className="color-wheel-container" onClick={handleClick}>
                    <svg
                        viewBox="0 0 100 100"
                        width="200"
                        height="200"
                        style={{ transform: `rotate(${angle}deg)` }}
                    >
                        {colors.map((color, index) => {
                            const startAngle = (index * 2 * Math.PI) / 6;
                            const endAngle = ((index + 1) * 2 * Math.PI) / 6;
                            const x1 = 50 + 50 * Math.cos(startAngle);
                            const y1 = 50 + 50 * Math.sin(startAngle);
                            const x2 = 50 + 50 * Math.cos(endAngle);
                            const y2 = 50 + 50 * Math.sin(endAngle);
                            const largeArcFlag = endAngle - startAngle > Math.PI ? 1 : 0;

                            return (
                                <path
                                    key={index}
                                    d={`M50,50 L${x1},${y1} A50,50 0 ${largeArcFlag},1 ${x2},${y2} Z`}
                                    fill={color}
                                    stroke="white"
                                    strokeWidth="1"
                                />
                            );
                        })}
                    </svg>
                    <div className="arrow">▼</div>
                </div>
            </div>
        </div>
    );
};

export default ColorWheel;
