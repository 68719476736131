// UpButton.js

import React, { useState, useEffect } from "react";
import "../components/up_button.css";


export default function UpButton() {
    const [showScrollUpBtn, setShowScrollUpBtn] = useState(false);

    // Function to handle the "Back to Top" button click
    useEffect(() => {
        const checkScrollTop = () => {
            if (!showScrollUpBtn && window.pageYOffset > 400) {
                setShowScrollUpBtn(true);
            } else if (showScrollUpBtn && window.pageYOffset <= 400) {
                setShowScrollUpBtn(false);
            }
        };

        window.addEventListener('scroll', checkScrollTop);
        return () => window.removeEventListener('scroll', checkScrollTop);
    }, [showScrollUpBtn]);

    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <button className={`scrollUpBtn ${showScrollUpBtn ? 'show' : ''}`} onClick={scrollTop}>
        <svg class="svgIcon" viewBox="0 0 384 512">
            <path
            d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
            ></path>
        </svg>
        </button>

    );
}