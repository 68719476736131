import React, { useEffect, useRef, useState } from "react";
import L from "leaflet";
import Chart from "chart.js/auto";
import "leaflet/dist/leaflet.css";
import "../components/styles.css";
import UpButton from "../components/UpButton.js";
import Footer from "../components/Footer";

export default function About() {
    const topFunction = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const map1Ref = useRef(null);
    const map2Ref = useRef(null);
    const nicsChartRef = useRef(null);
    const amandasChartRef = useRef(null);
    const nicsChartInstance = useRef(null); // To keep track of Nic's chart instance
    const amandasChartInstance = useRef(null); // To keep track of Amanda's chart instance

    const map1Instance = useRef(null);
    const map2Instance = useRef(null);

    const [isNicOpen, setIsNicOpen] = useState(false);
    const [isAmandaOpen, setIsAmandaOpen] = useState(false);

    const toggleNicSection = () => {
        setIsNicOpen(!isNicOpen);
    };

    const toggleAmandaSection = () => {
        setIsAmandaOpen(!isAmandaOpen);
    };

    useEffect(() => {
        if (isNicOpen && map1Ref.current && !map1Instance.current) {
            map1Instance.current = L.map(map1Ref.current).setView([-31.9505, 115.8605], 6);
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
                attribution: '© OpenStreetMap contributors'
            }).addTo(map1Instance.current);

            const pulsingIcon1 = L.divIcon({
                className: 'pulsing-icon-about',
                iconSize: [10, 10]
            });

            L.marker([-31.9505, 115.8605], { icon: pulsingIcon1 }).addTo(map1Instance.current);
        }

        if (isAmandaOpen && map2Ref.current && !map2Instance.current) {
            map2Instance.current = L.map(map2Ref.current).setView([-6.1944, 106.8229], 6);
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
                attribution: '© OpenStreetMap contributors'
            }).addTo(map2Instance.current);

            const pulsingIcon2 = L.divIcon({
                className: 'pulsing-icon-about',
                iconSize: [10, 10]
            });

            L.marker([-6.1944, 106.8229], { icon: pulsingIcon2 }).addTo(map2Instance.current);
        }

        // Cleanup maps when sections are closed
        return () => {
            if (!isNicOpen && map1Instance.current) {
                map1Instance.current.remove();
                map1Instance.current = null;
            }
            if (!isAmandaOpen && map2Instance.current) {
                map2Instance.current.remove();
                map2Instance.current = null;
            }
        };
    }, [isNicOpen, isAmandaOpen]);

    useEffect(() => {
        const nicsData = {
            labels: ['Words of Affirmation', 'Acts of Service', 'Receiving Gifts', 'Quality Time', 'Physical Touch'],
            datasets: [{
                label: 'Nic\'s Love Languages',
                data: [10, 13, 20, 33, 23],
                backgroundColor: ['#FFC0CB', '#B0E0E6', '#FFDAB9', '#E6E6FA', '#F0E68C'],
                hoverOffset: 4
            }]
        };

        const amandasData = {
            labels: ['Words of Affirmation', 'Acts of Service', 'Receiving Gifts', 'Quality Time', 'Physical Touch'],
            datasets: [{
                label: 'Amanda\'s Love Languages',
                data: [23, 20, 0, 30, 27],
                backgroundColor: ['#FFC0CB', '#B0E0E6', '#FFDAB9', '#E6E6FA', '#F0E68C'],
                hoverOffset: 4
            }]
        };

        if (isNicOpen && nicsChartRef.current && !nicsChartInstance.current) {
            nicsChartInstance.current = new Chart(nicsChartRef.current, {
                type: 'pie',
                data: nicsData,
            });
        }

        if (isAmandaOpen && amandasChartRef.current && !amandasChartInstance.current) {
            amandasChartInstance.current = new Chart(amandasChartRef.current, {
                type: 'pie',
                data: amandasData,
            });
        }

        return () => {
            if (nicsChartInstance.current) {
                nicsChartInstance.current.destroy();
                nicsChartInstance.current = null;
            }
            if (amandasChartInstance.current) {
                amandasChartInstance.current.destroy();
                amandasChartInstance.current = null;
            }
        };
    }, [isNicOpen, isAmandaOpen]);

    return (
        <div className="overall">
            <UpButton />

            <header className="container site-header">
                <div className="text-center">
                    <img src="gifs/boyGif.gif" style={{ width: "10%", marginRight: "20px" }}></img>
                    <h1>About Nic and Amanda</h1>
                    <img src="gifs/girlGif.gif" style={{ width: "10%", marginLeft: "20px" }}></img>
                </div>
            </header>

            <section id="about-section">
                <div className="container">
                    <div className="row">
                        <div id="person">
                            <div className={`bg-blue ${isNicOpen ? 'open' : ''}`} style={{ padding: '30px', cursor: 'pointer' }} onClick={toggleNicSection}>
                                <div className="d-flex flex-column">
                                    <div className="name-title">
                                        <h2>About Nichowas&lt;3</h2> <img src="nicpic.png" style={{ width: "100px", height: "100px", marginLeft: "30px" }} />
                                    </div>
                                    <div id="about-content" className={`${isNicOpen ? 'show' : ''}`}>
                                        {isNicOpen && (
                                            <>
                                                <ul>
                                                    <li><b>Name:</b> Nicholas Zijian Chua</li>
                                                    <li><b>Birthday:</b> 01/03/2002</li>
                                                    <li><b>Born in:</b> Perth, Australia</li>
                                                    <li><b>Hobbies:</b> Gaming, Music, Coding</li>
                                                    <li><b>Favourite Food:</b> Cheese Burger</li>
                                                    <li><b>Favourite Colour:</b> Blue</li>
                                                    <li><b>Current Favourite TV Show:</b> The Boys</li>
                                                </ul>

                                                <h3>Nic is from:</h3>
                                                <div id="map1" ref={map1Ref} style={{ height: "400px" }}></div>

                                                <h3>Nic's Personality</h3>
                                                <img src={require("../components/about-images/nicsPersonality.png")} id="personality" />

                                                <h3>Nic's Love Languages</h3>
                                                <canvas id="nicsLoveLanguages" ref={nicsChartRef}></canvas>
                                                <p>Me and my baby got quality timeee</p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="person">
                            <div className={`bg-green ${isAmandaOpen ? 'open' : ''}`} style={{ padding: '30px', cursor: 'pointer' }} onClick={toggleAmandaSection}>
                                <div className="d-flex flex-column">
                                    <div className="name-title">
                                        <h2>About Amanda💖💖</h2> <img src="amandapic.png" style={{ width: "100px", height: "100px", marginLeft: "30px" }} />
                                    </div>
                                    <div id="about-content" className={`${isAmandaOpen ? 'show' : ''}`}>
                                        {isAmandaOpen && (
                                            <>
                                                <ul>
                                                    <li><b>Name:</b> Amanda Malika Shafira</li>
                                                    <li><b>Birthday:</b> 08/12/2003</li>
                                                    <li><b>Born in:</b> Jakarta, Indonesia</li>
                                                    <li><b>Hobbies:</b> F1, Reading, TV Shows</li>
                                                    <li><b>Favourite Food:</b> Fried Rice</li>
                                                    <li><b>Favourite Colour:</b> Green</li>
                                                    <li><b>Current Favourite TV Show:</b> Derry Girls</li>
                                                </ul>

                                                <h3>Amanda is from:</h3>
                                                <div id="map2" ref={map2Ref} style={{ height: "400px" }}></div>

                                                <h3>Amanda's Personality</h3>
                                                <img src={require("../components/about-images/amandasPersonality.jpg")} id="personality" />

                                                <h3>Amanda's Love Languages</h3>
                                                <canvas id="amandasLoveLanguages" ref={amandasChartRef}></canvas>
                                                <p>No gift giving wtff!! (To be fair this was a long time ago, maybe we should do it
                                                    again and see if it changed hehe)</p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
