import styles from "../components/styles.css";
import React, { useState, useEffect } from 'react';
import Footer from "../components/Footer";
import Papa from 'papaparse';
import Carousel from 'react-bootstrap/Carousel';
import confetti from 'canvas-confetti';

export default function Home() {
    const [photos, setPhotos] = useState([]);
    const [photoUrls, setPhotoUrls] = useState({});
    const [timeLeft, setTimeLeft] = useState('');
    const [celebrationText, setCelebration] = useState('');

    // Function to handle the "Back to Top" button click
    const topFunction = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // Extracted countdown logic into a function
    const updateCountdown = () => {
        const now = new Date();
        let year = now.getFullYear();
        let month = now.getMonth();
        let day = now.getDate();

        // If today is after June 28, target next year's June 28
        if (month > 5 || (month === 5 && day >= 28)) {
            year++;
        }

        const nextJune28 = new Date(year, 5, 28); // Month is 0-indexed, 5 = June
        const diff = nextJune28 - now;

        // Calculate time left
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((diff / 1000 / 60) % 60);
        const seconds = Math.floor((diff / 1000) % 60);

        setTimeLeft(`${days} days ${hours} hours ${minutes} minutes ${seconds} seconds`);
    };

    useEffect(() => {
        // Call once immediately to avoid initial delay
        updateCountdown();

        // Then set up the interval
        const now = new Date();
        let year = now.getFullYear();
        let month = now.getMonth();
        let day = now.getDate();

        const diff = year - 2022;

        if (month === 5 && day === 28) {
            if (diff % 10 === 1) {
                setCelebration(`🥳🎉 Happy ${diff}st Anniversary!! 🎉🥳`);
            }
            else if (diff % 10 === 2) {
                setCelebration(`🥳🎉 Happy ${diff}nd Anniversary!! 🎉🥳`);
            }
            else if (diff % 10 === 3) {
                setCelebration(`🥳🎉 Happy ${diff}rd Anniversary!! 🎉🥳`);
            }
            else {
                setCelebration(`🥳🎉 Happy ${diff}th Anniversary!! 🎉🥳`);
            }

            // Trigger confetti effect
            confetti({
                particleCount: 100,
                spread: 70,
                origin: { y: 0.6 }
            });
        } else {
            const interval = setInterval(updateCountdown, 1000);
            return () => clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        // Fetch and parse CSV data
        const fetchPhotos = () => {
            Papa.parse("slideshow.csv", {
                download: true,
                header: true,
                complete: function (results) {
                    const selectedPhotos = results.data.map(photo => photo.filename); // Assuming 'filename' is a column in your CSV
                    setPhotos(selectedPhotos);
                }
            });
        };

        fetchPhotos();
    }, []);

    useEffect(() => {
        if (photos.length > 0) {
            // Fetch signed URLs from the backend
            const fetchSignedUrls = async () => {
                try {
                    const response = await fetch('https://www.nicandamanda.com/api/signed-urls', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ filenames: photos }),
                    });
                    const data = await response.json();
                    setPhotoUrls(data);
                } catch (error) {
                    console.error('Error fetching signed URLs:', error);
                }
            };

            fetchSignedUrls();
        }
    }, [photos]);

    return (
        <div className="overall">
            <header className="container site-header">
                <div className="text-center">
                    <h1>Nic ♡ Amanda</h1>
                    <img src="gifs/cuddleGif.gif" style={{ width: "10%", marginLeft: "20px" }}></img>
                </div>

            </header>

            {celebrationText && (
                <section id="celebration-section" className="container text-center">
                    <h1>{celebrationText}</h1>
                </section>
            )}

            <section id="countdown-section" className="container">
                <div className="text-center">
                    <h2>Countdown to Our Next Anniversary</h2>
                </div>
                <div className="text-center">

                    <div id="countdown">
                        {timeLeft}
                    </div>
                </div>
            </section>

            <div id="photoSlideshow" className="carousel slide container" data-bs-ride="carousel">
                <h3 className="text-center">Here is a slideshow of some random moments!</h3>
                <Carousel>
                    {photos.map((photo, index) => (
                        <Carousel.Item key={index}>
                            <div className="slideshow-image-holder">
                                <img src={photoUrls[photo] || ''} alt="Photo" />
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
            <Footer />
        </div>
    );
}
