import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import "./NavBar.css";
import "./styles.css";
import "./special_styles.css";
import Modal from './Modal';

export default function NavBar() {
    const [isAccessible, setIsAccessible] = useState(false);

    useEffect(() => {
        const currentDate = new Date();
        const targetDate = new Date('2024-06-27');

        if (currentDate > targetDate) {
            setIsAccessible(true);
        }
    }, []);

    const handleLinkClick = (e) => {
        if (!isAccessible) {
            e.preventDefault();
            setShowModal(true);
        }
    };

    const [showModal, setShowModal] = useState(false);

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light overall">
            <div className="container">
                <Link to="/home" className="no-underline-link">
                    <a className="navbar-brand">
                        <img src={require('./cartoons/a&n.png')} id="logo" alt="Logo" />Our Anniversary
                    </a>
                </Link>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <Link to="/about" className="no-underline-link">
                                <a className="nav-link">About Us 📄</a>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/timeline" className="no-underline-link">
                                <a className="nav-link">Timeline ⌛</a>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/places" className="no-underline-link">
                                <a className="nav-link">Places 🗺️</a>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/things" className="no-underline-link">
                                <a className="nav-link">Amanda's Things 🌳</a>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/gallery" className="no-underline-link">
                                <a className="nav-link">Our Gallery 📸</a>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link 
                                to={isAccessible ? "/messages" : "#"} 
                                className="no-underline-link" 
                                onClick={handleLinkClick}
                            >
                                <a className="nav-link">
                                    {isAccessible ? "Anniversary Letter 💌" : "Anniversary Letter 🚫"}
                                </a>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <Link to="/" className="no-underline-link">
                <a className="nav-link">Logout ➡️</a>
            </Link>
            <Modal show={showModal} handleClose={closeModal}>
                <p>Your letter will be accessible on June 28, 2024.</p>
            </Modal>
        </nav>
    );
}
