// Head.js

import React from 'react';
// import "./Head.css";
import "./styles.css"


export default function Head() {
    return (
        <head>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>Our Anniversary</title>
            <link rel="icon" href="../components/cartoons/heart.png" type="image/png" />
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/css/bootstrap.min.css" rel="stylesheet" />
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
        </head>
    );
}
