import React, { useEffect, useState } from "react";
import Papa from "papaparse"; // Ensure PapaParse is installed (`npm install papaparse`)
import "../components/styles.css";
import UpButton from "../components/UpButton.js";
import Footer from "../components/Footer";

export default function Timeline() {
    const [events, setEvents] = useState([]);

    // Function to handle the "Back to Top" button click
    const topFunction = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // Function to create timeline event elements
    const createEventElement = (item, index) => {
        return (
            <div className="timeline-event" key={item.name}>
                <div className="event-image">
                    <img src={require("../components/cartoons/" + item.imagePath)} alt={item.name} id="eventImage" />
                </div>
                <h4 className="event-title">{index + 1}. {item.name}</h4>
                <p>{item.date}</p>
            </div>
        );
    };

    // Fetch and process CSV data on component mount
    useEffect(() => {
        Papa.parse("events.csv", {
            download: true,
            header: true,
            complete: function (results) {
                setEvents(results.data);
            }
        });
    }, []);

    // Split the events into rows
    const timelineRows = [];
    for (let i = 0; i < events.length; i += 3) {
        const rowItems = events.slice(i, i + 3);
        const isEvenRow = timelineRows.length % 2 === 0;
        // const rowContent = isEvenRow ? rowItems : [...rowItems].reverse();
        const rowContent = rowItems;
        timelineRows.push(
            <div className="timeline-row" key={i}>
                {rowContent.map((item, index) => createEventElement(item, i + index))}
            </div>
        );
    }

    return (
        <div className="overall">
            <UpButton />
            <header className="container site-header">
                <div className="text-center">
                    <h1>Our Timeline</h1>
                    <img src="gifs/funGif.gif" style={{ width: "10%", marginLeft: "20px" }}></img>
                </div>
            </header>

            <section id="timeline">
                <div className="container">
                    <div className="timeline-snake">
                        {timelineRows}
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
