import React, { useState } from 'react';
import './styles.css';

const NumberInput = ({ min = 1, max = 10, step = 1, onValueChange }) => {
    const [value, setValue] = useState(1);

    const handleChange = (e) => {
        const newValue = e.target.value;
        setValue(newValue);
        onValueChange(newValue);
    };

    return (
        <div className="range-input-container">
            <h6><label htmlFor="range-input">What is Amanda's Favourite Number?</label></h6>
            <input
                id="range-input"
                type="range"
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={handleChange}
            />
            <div className="scale">
                {Array.from({ length: max - min + 1 }, (_, i) => min + i).map((num) => (
                    <span key={num} className={`scale-number ${num === parseInt(value) ? 'selected' : ''}`}>
                        {num}
                    </span>
                ))}
            </div>
        </div>
    );
};

export default NumberInput;
