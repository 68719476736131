import React, { useEffect, useState, useRef } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css"; // Make sure to import Leaflet's CSS
import "../components/styles.css";
import Footer from "../components/Footer";
import "../components/special_styles.css"

// Assuming you have a JSON version of your marker data
import markerData from "../components/markerData.json";

export default function Places() {
    const mapRef = useRef(null);
    const [mapLoaded, setMapLoaded] = useState(false); // Track if the map has been initialized

    const [checkedState, setCheckedState] = useState({
        picnic: false,
        dance: false,
        eatOut: false,
        mcdonalds: false,
    });

    // Initialize the map
    useEffect(() => {
        // Initialize the map
        mapRef.current = L.map('map').setView([-31.975, 115.8605], 11);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '© OpenStreetMap contributors'
        }).addTo(mapRef.current);

        setMapLoaded(true); // Indicate that the map has been loaded

        // Cleanup function to run when the component unmounts
        return () => {
            if (mapRef.current) {
                mapRef.current.remove();
            }
        };
    }, []);

    // Function to create and add markers to the map
    const addMarkers = (category) => {
        markerData[category].locations.forEach(location => {
            console.log(location)
            if (!location.marker) {
                const icon = L.divIcon({
                    className: `pulsing-icon-${category}`,
                    iconSize: [6, 6]
                });
                location.marker = L.marker([location.coord[0], location.coord[1]], { icon })
                    .addTo(mapRef.current)
                    .bindTooltip(`${location.name}`, {
                        permanent: false,
                        direction: 'top',
                        offset: L.point(0, -20),
                    });
            }
        });
    };

    // Function to remove markers from the map
    const removeMarkers = (category) => {
        markerData[category].locations.forEach(location => {
            if (location.marker) {
                mapRef.current.removeLayer(location.marker);
                location.marker = null;
            }
        });
    };

    // Handle checkbox changes
    const handleCheckboxChange = (event) => {
        setCheckedState({
            ...checkedState,
            [event.target.id]: event.target.checked,
        });
    };

    // Add or remove markers when checkboxes change
    useEffect(() => {
        Object.keys(checkedState).forEach(category => {
            if (checkedState[category]) {
                addMarkers(category);
            } else {
                removeMarkers(category);
            }
        });
    }, [checkedState]);

    const getColor = (category) => {
        switch(category) {
            case 'picnic':
                return 'green';
            case 'dance':
                return 'red';
            case 'eatOut':
                return 'blue';
            case 'mcdonalds':
                return 'orange';
            default:
                return 'black';
        }
    };

    return (
        <div className="overall">
            <header className="container site-header">
                <div className="text-center">
                    <h1>Places We Have Been!</h1>
                    <img src="gifs/eatGif.gif" style={{ width: "10%", marginLeft: "20px" }}></img>
                </div>
            </header>

            <section id="content">
                <div className="container">
                    <div className="toggle-controls">
                        <h2>Pick a category to see on the map!</h2>
                        {Object.keys(checkedState).map((category, index) => (
                            <div className="checkbox-container" key={index}>
                                <div className="heart-container" title="Like">
                                    <input type="checkbox" className="checkbox" id={category} onChange={handleCheckboxChange} checked={checkedState[category]}/>
                                    <div className="svg-container">
                                        <svg viewBox="0 0 24 24" className="svg-outline" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z" style={{ fill: getColor(category) }}>
                                            </path>
                                        </svg>
                                        <svg viewBox="0 0 24 24" className="svg-filled" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z" style={{ fill: getColor(category) }}>
                                            </path>
                                        </svg>
                                        <svg className="svg-celebrate" width="100" height="100" xmlns="http://www.w3.org/2000/svg">
                                            <polygon points="10,10 20,20" style={{ fill: getColor(category) }}></polygon>
                                            <polygon points="10,50 20,50" style={{ fill: getColor(category) }}></polygon>
                                            <polygon points="20,80 30,70" style={{ fill: getColor(category) }}></polygon>
                                            <polygon points="90,10 80,20" style={{ fill: getColor(category) }}></polygon>
                                            <polygon points="90,50 80,50" style={{ fill: getColor(category) }}></polygon>
                                            <polygon points="80,80 70,70" style={{ fill: getColor(category) }}></polygon>
                                        </svg>
                                    </div>
                                </div>
                                <label htmlFor={category} className="checkbox-label">{markerData[category].name}</label>
                            </div>
                        ))}
                    </div>
                    <div id="map" style={{ height: "600px" }}></div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
