// src/components/Acknowledgment.js
import React from "react";
import "../components/styles.css";

export default function Acknowledgment() {
    return (
        <div className="acknowledgment">
            <a>
                GIFs provided by <a href="https://tenor.com/en-GB/users/peachgomacute" target="_blank" rel="noopener noreferrer">peachGomaCute</a>
            </a>
        </div>
    );
}
