import React, { useState } from "react";
import "../components/styles.css";
import Footer from "../components/Footer";

export default function Messages() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleBook = () => {
        setIsOpen(!isOpen);
    };

    // Function to handle the "Back to Top" button click
    const topFunction = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className="overall">
            <section id="love-messages">
                <div className="container">
                    <div className="book-container">
                        <div className={`book ${isOpen ? "open" : ""}`} onClick={toggleBook}>
                            <div className="book-text">
                                <p>Dear my beautiful princess,
                                    <br /><br /><br /><br />
                                    Happy Second Anniversary!!!
                                    <br /><br />
                                    First of all, how on earth has it been 2 years already wth 😭
                                    <br /><br />
                                    Thank you for always being my rock, my girlfriend and my best fwend hehe. I love you so much, more than I can ever (and will ever be able to) put into words. Every time we are together, I feel like im finally breathing fresh air again, and although we sometimes spend entire days together, I always go home missing you and feeling like we didn’t have enough time together (like a cloud on my head).
                                    <br /><br />
                                    In the last 2 years, we have done so much stuff together my brain is overflowing with core memories, but somehow my favourites are always the ones of us snuggling together under your blanket, or cuddling together on a picnic, pretending the rest of the world doesn’t exist.
                                    <br /><br />
                                    I am so so looking forward to spending my life with you, travelling together, sleeping together, doing laundry together, getting sick together, buying our first house together, sharing music, sharing our thoughts on random things, sharing Instagram reels, and just all the big and small things the world has to offer. Sometimes I feel like I wanna just skip ahead to when we are able to do all these things, but it terrifies me that the last 2 years with you felt like the fastest 3 seconds of my life, and it freaks me out even more that the next 10, 20 or 100 will go by in a blink of an eye (but that’s life I guess, and itll be oke as long as I get to spend it with you).
                                    <br /><br />
                                    To be honest, I cannot image what I would be doing without you, and I will always say this but I feel so lucky to be with the amazing, kind, gentle and loving person that is you. I love you so fucking much, and I promise to never give up on you easily.
                                    <br /><br />
                                    Btw I hope you like your gift my baby, im not very good at scrapbooking but I like to code and everything is digital nowadays so I thought id try to put a digital scrapbook together hehe with the help of my tutor and good friend (ChatGPT).
                                    <br /><br /><br />
                                    P.S. Thank you for reading this letter and putting up with my cringe for 2 years even though sometimes it can be quite unsettling

                                    <br /><br /><br /><br />
                                    Yours forever and ever,
                                    <br />
                                    {/* <img src={require('../components/cartoons/bunny.gif') + '?v=' + new Date().getTime()} alt="Romantic GIF" style={{width: '100%', height: 'auto'}} /> */}
                                    Nichowas&lt;3
                                    <img src="gifs/insideGif.gif" style={{ width: "20%" }}></img>
                                </p>
                            </div>

                            <div className="cover">
                                <div className="cover-text">
                                    <h1>🩷 Happy Anniversary!! 🩷</h1>
                                    <img src="gifs/letterGif.gif" style={{ width: "50%" }}></img>
                                    <p>(Click Me)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
