import React from "react";
// import styles from "../components/styles2.css"

import styles from "../components/styles2.module.css";
import UpButton from "../components/UpButton.js";



export default function Things() {

    return (
        <div className={styles.overall}>
            <UpButton />

            <header className={`${styles.site_header} container`}>
                <div className="text-center">
                    <img src="gifs/things1Gif.gif" style={{ width: "10%", marginRight: "20px" }}></img>
                    <h1>Amanda's Things</h1>
                    <img src="gifs/things2Gif.gif" style={{ width: "10%", marginLeft: "20px" }}></img>
                </div>
            </header>


            <section id="about-section">
                <div className={styles.container}>
                    <h2 className={styles.things}>Amanda's Vibe</h2>
                    <div className={styles.vibe}>
                        <div className={styles.grid}>
                            <div><img src={require("../components/vibe/beetle.png")} className={styles.vibe_img} /></div>
                            <div><img src={require("../components/vibe/roses.png")} className={styles.vibe_img} /></div>
                            <div><img src={require("../components/vibe/food.jpg")} className={styles.vibe_img} /></div>
                            <div><img src={require("../components/vibe/panda.jpeg")} className={styles.vibe_img} /></div>
                            <div><img src={require("../components/vibe/moana.png")} className={styles.vibe_img} /></div>
                            <div><img src={require("../components/vibe/picnic.jpg")} className={styles.vibe_img} /></div>
                            <div><img src={require("../components/vibe/spring.jpg")} className={styles.vibe_img} /></div>
                            <div><img src={require("../components/vibe/house.png")} className={styles.vibe_img} /></div>
                            <div><img src={require("../components/vibe/SUNSET.jpeg")} className={styles.vibe_img} /></div>
                        </div>
                    </div>
                </div>
            </section>



            <section id="about-section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="d-flex flex-column align-items-stretch">
                                <h2 className={styles.things}>Things Amanda Loves 👍</h2>
                                <ul id="list-of-things">
                                    <li>📱 TikTok 📱</li>
                                    <li>🥛 Milkies 🥛</li>
                                    <li>🍚 Fried Rice 🍚</li>
                                    <li>🌶️ Spicy Food 🌶️</li>
                                    <li>8️⃣ The Number 8 8️⃣</li>
                                    <li>🧃 Cranberry juice🧃</li>
                                    <li>🧴 Skin care routine 🧴</li>
                                    <li>⛵ Holiday to Greece ⛵</li>
                                    <li>🗣️ Gaslighting people 🗣️</li>
                                    <li>🍽️ Trying new restaurants 🍽️</li>
                                    <li>💃 Going out with her friends 💃</li>
                                    <li>🐶 Teddy and Archie and Leooo 🐶</li>
                                    <li>🍓 Strawberries and chocolate dip 🍓</li>
                                    <li>👕 Wearing my clothes especially my hoodies 👕</li>
                                    <li>🫧 Head and shoulders shampoo and conditioner 🫧</li>
                                    <li>📺 Random Netflix movies and shows 📺</li>
                                    <li>🎵 She has amazing music taste 🎵</li>
                                    <li>💧 Nice cold non-tappy water 💧</li>
                                    <li>🥘 Noodle soup with eggs 🥘</li>
                                    <li>🍪 Indonesian snacks 🍪</li>
                                    <li>🏮 Lantern Festival 🏮</li>
                                    <li>🍫 Forero Roche 🍫</li>
                                    <li>👸 Bridgerton 👸</li>
                                    <li>🍜 RAMEN!! 🍜</li>
                                    <li>🧺 Picnics 🧺</li>
                                    <li>🌹 Roses 🌹</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="d-flex flex-column align-items-stretch">
                                <h2 className={styles.things}>Things Amanda HATES 👎</h2>
                                <ul id="list-of-things">
                                    <li>🤢 Wasabi 🤢</li>
                                    <li>🌿 Coriander 🌿</li>
                                    <li>🚱 Tappy water 🚱</li>
                                    <li>👑 Working at Crown 👑</li>
                                    <li>🙅 People cancelling on her 🙅</li>
                                    <li>👹 Men (especially the creepy ones) 👹</li>
                                    <li>😭 Being away from her baby for too long😭</li>
                                    <li>🤡 Entitled and rude people 🤡</li>
                                    <li>💩 Stinky people 💩</li>
                                    <li>🥤 Grass jelly 🥤</li>
                                    <li>😵‍💫 Pimples 😵‍💫</li>
                                    <br />
                                    <li>(My baby doesn't hate a lot of things)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="about-section">
                <div className={styles.container}>
                    <h2 className={styles.things}>Things I love About Amanda</h2>
                    <img src={require("../components/about-images/diagram.png")} id="diagram" />
                </div>
            </section>

            <section id="about-section">
                <h2 className={styles.things}>More Things I love About Amanda</h2>
                <ul id="list-of-things">
                    <li>🤓 You are fucking smart 🤓</li>
                    <li>📣 You are my #1 supporter 📣</li>
                    <li>😊 The way you smile at me 😊</li>
                    <li>🤗 You are always caring to me 🤗</li>
                    <li>👂 You are an amazing listener hihi 👂</li>
                    <li>⚡ You match my energy so well omg⚡</li>
                    <li>🤑 You give me money when I am broke 🤑</li>
                    <li>🤩 You are literally my dream come true 🤩</li>
                    <li>🙏 You are always patient and gentle to me 🙏</li>
                    <li>📱 I wake up every day to a good morning text 📱</li>
                    <li>😂 You always make me laugh, even on my bad days 😂</li>
                    <li>🫡 I can always trust you in everything you do, every day 🫡</li>
                    <li>😋 You always let me eat some of your food if I'm hungry or I like it 😋</li>
                    <li>😘 You always give me hugs and kisses, especially when I need it the most 😘</li>
                    <li>🤔 You always say the right thing I need to hear, especially when I overthink things 🤔</li>
                    <li>🥰 You are always kind to me, your family, friends and even people you don't even know 🥰</li>
                    <li>😍 You are so fucking beautiful I fell in love with you the second I caught a glimpse of you 😍</li>
                    <li>🤠 You are adventurous and willing to try new things, I can't wait to experience everything with you 🤠
                    </li>
                    <li>👐 You are always generous with your time, energy and money without expecting anything in return 👐</li>
                    <li>😊 You are always optimistic, and always see the good in situations when I cannot get over the negatives
                        😊</li>
                </ul>
            </section>


            <section id="about-section">
                <h2 className={styles.things}>Things I Don't Like</h2>
                <ul id="list-of-things">
                    <li>😣 When you're mean to me 😣</li>
                </ul>
            </section>



            <section>
                <div className="container" id="about-section">
                    <h2 className={styles.things}>Songs That Remind Me of You</h2>
                    <div id="albums" className={styles.albums_grid}>
                        <div >
                            <img src={require("../components/album-images/alrighty aphrodite.jpg")} className={styles.album_image} />
                            <h4 id="song-title">Alrighty Aphrodite</h4>
                            <p class="text-center">Peach Pit</p>
                        </div>
                        <div >
                            <img src={require("../components/album-images/bound.jpg")} className={styles.album_image} />
                            <h4 id="song-title">Bound</h4>
                            <p class="text-center">Ponderosa Twins Plus One</p>
                        </div>
                        <div >
                            <img src={require("../components/album-images/lovers rock.png")} className={styles.album_image} />
                            <h4 id="song-title">Lovers Rock</h4>
                            <p class="text-center">TV Girl</p>
                        </div>
                        <div >
                            <img src={require("../components/album-images/mardy bum.jpg")} className={styles.album_image} />
                            <h4 id="song-title">Mardy Bum</h4>
                            <p class="text-center">Arctic Monkeys</p>
                        </div>
                        <div >
                            <img src={require("../components/album-images/mine all mine.jpg")} className={styles.album_image} />
                            <h4 id="song-title">My love Mine All Mine</h4>
                            <p class="text-center">Mitski</p>
                        </div>
                        <div >
                            <img src={require("../components/album-images/young and beautiful.jpg")} className={styles.album_image} />
                            <h4 id="song-title">Young and Beautiful</h4>
                            <p class="text-center">Lana Del Rey</p>
                        </div>
                        <div >
                            <img src={require("../components/album-images/nothing.jpg")} className={styles.album_image} />
                            <h4 id="song-title">Nothing</h4>
                            <p class="text-center">Bruno Major</p>
                        </div>
                        <div >
                            <img src={require("../components/album-images/fix you.jpg")} className={styles.album_image} />
                            <h4 id="song-title">Fix You</h4>
                            <p class="text-center">Coldplay</p>
                        </div>
                        <div >
                            <img src={require("../components/album-images/marry you.jpg")} className={styles.album_image} />
                            <h4 id="song-title">Marry You</h4>
                            <p class="text-center">Bruno Mars</p>
                        </div>
                    </div>
                </div>
            </section>

            <footer className={styles.site_footer}>
                <div class="container">
                    <p>&copy; 2024 Nic & Amanda. All rights reserved.</p>
                    <p id="insta_box">Follow us on social media:<a target="_blank" href="https://www.instagram.com/mateo_number1_fan/">
                        <button class="insta">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 448 512" class="svgIcon"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path></svg>
                            <span class="insta_text">Instagram</span>
                        </button></a></p>
                </div>
            </footer>
        </div>
    )
}