import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import UpButton from "../components/UpButton.js";
import imageData from "../components/photos.json"; // Assuming JSON data is imported
import imageData2 from "../components/photos2.json"; // Assuming JSON data is imported

export default function Gallery() {
    const [popup, setPopup] = useState({ isOpen: false, src: "", caption: "" });
    const [activeYear, setActiveYear] = useState("");
    const [imageUrls, setImageUrls] = useState({});

    // Group images by year and month
    const groupImagesByYearAndMonth = (images) => {
        const groupedImages = {};

        images.forEach((photo) => {
            const dateTaken = photo['Date Taken'];
            if (dateTaken !== "Unknown") {
                const [year, month] = dateTaken.split(' ')[0].split('-');
                if (!groupedImages[year]) {
                    groupedImages[year] = {};
                }
                if (!groupedImages[year][month]) {
                    groupedImages[year][month] = [];
                }
                groupedImages[year][month].push(photo);
            }
        });

        return groupedImages;
    };

    const groupedImages = groupImagesByYearAndMonth(imageData);

    // Get unique years from the grouped images
    const years = Object.keys(groupedImages).sort();

    useEffect(() => {
        // Set the active year to the first year if not already set
        if (!activeYear && years.length > 0) {
            setActiveYear(years[0]);
        }

        // Fetch image URLs from the backend
        const fetchImageUrls = async () => {
            try {
                const response = await fetch('https://www.nicandamanda.com/api/images');
                const data = await response.json();
                setImageUrls(data);
            } catch (error) {
                console.error('Error fetching images FRONTEND:', error);
            }
        };

        fetchImageUrls();

        // Function to close popup on keydown
        const handleKeyDown = (event) => {
            if (popup.isOpen && (event.key === "Escape" || event.key === " ")) {
                closePopup();
            }
        };

        // Function to close popup on click outside
        const handleClickOutside = (event) => {
            if (popup.isOpen && event.target.className.includes("image-popup")) {
                closePopup();
            }
        };

        if (popup.isOpen) {
            window.addEventListener("keydown", handleKeyDown);
            window.addEventListener("click", handleClickOutside);
        }

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("click", handleClickOutside);
        };
    }, [popup.isOpen, activeYear, years]);

    const handleImageClick = (src, caption) => {
        setPopup({ isOpen: true, src, caption });
    };

    const closePopup = () => {
        setPopup({ isOpen: false, src: "", caption: "" });
    };

    const renderImagesForYear = (year) => {
        const months = Object.keys(groupedImages[year]).sort();
        return (
            <div key={year}>
                {months.map((month) => (
                    <div key={month}>
                        <h3>{new Date(year, month - 1).toLocaleString('default', { month: 'long' })} {year}</h3>
                        <div className="row">
                            {groupedImages[year][month].map((photo, index) => (
                                <div key={index} className="gallery-item col-lg-4 col-md-6 col-sm-12 mb-4">
                                    <img
                                        src={imageUrls[photo.Filename] || ''}
                                        alt="Photo"
                                        className="img-fluid img-thumbnail"
                                        onClick={() => handleImageClick(imageUrls[photo.Filename], photo['Date Taken'])}
                                    />
                                    <div className="caption text-center">{photo['Date Taken']}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="overall">
            <UpButton />
            <header className="container site-header">
                <div className="text-center">
                    <h1>Photo Gallery</h1>
                    <img src="gifs/photoGif.gif" style={{ width: "10%", marginLeft: "20px" }}></img>
                </div>
            </header>

            <section id="photo-gallery">
                <div className="gallery-container">
                    <div className="gallery-tabs">
                        {years.map((year) => (
                            <button
                                key={year}
                                className={`gallery-tab ${activeYear === year ? 'active' : ''}`}
                                onClick={() => setActiveYear(year)}
                            >
                                {year}
                            </button>
                        ))}
                    </div>
                    {activeYear && renderImagesForYear(activeYear)}
                </div>
            </section>

            {popup.isOpen && (
                <div id="image-popup" className="image-popup">
                    <span className="image-close" onClick={closePopup}>&times;</span>
                    <img className="popup-image" src={popup.src} alt="Popup" />
                    <div className="caption">{popup.caption}</div>
                </div>
            )}
            <Footer />
        </div>
    );
}
